/** ========================================================================
 * Match Height
 * Add these classes will add an inline height to the element based on the tallest in the group.
 * ======================================================================== */
(function ($) {

  // On load
  $('.flex > *').matchHeight();

  // On gravity form validation
  $(document).bind('gform_confirmation_loaded', function () {
    $('.flex > *').matchHeight();
  });

  // Fix after update
  function lastElement() {
    $('.flex > *').each(function () {
      var attr = $(this).attr('style');
      if (attr === "" || typeof attr === typeof undefined || attr === false) {
        $(this).addClass('unflex');
      }
    });
  }

  // Before Resize
  $.fn.matchHeight._beforeUpdate = function (event, groups) {
    $('.unflex').each(function () {
      $(this).removeClass('unflex');
    });
  };
  // After inital load
  $.fn.matchHeight._afterUpdate = function (event, groups) {
    lastElement();
  };

  // Resize flex after Text Resizer fires. Timer is to allow css animations to finish.
  $('#resize a').click(function() {
    setTimeout(function(){
      $('.flex > *').matchHeight();
    }, 750);
  });

})(jQuery);
