/** ========================================================================
 * Owl Carousel
 * ======================================================================== */
(function ($) {

  if($('body').hasClass('home')) {
    var owl = '.owl-carousel';
    $(owl).owlCarousel({
      stagePadding: 50,
      startPosition: 0,
      margin:20,
      center:false,
      nav:true,
      dots: false,
      controlsClass: 'bgPrimary',
      navSpeed: 750,
      responsive:{
        0:{
          margin:10,
          startPosition: 0,
          items:1
        },
        650:{
          stagePadding: 50,
          startPosition: 0,
          items:2
        },
        1200:{
          stagePadding: 100,
          items:3
        },
        1600:{
          stagePadding: 200,
          margin:60,
          items:3
        }
      }
    });
    $('.flexMe').matchHeight({
      byRow: false
    });
    $(owl).on('resized.owl.carousel', function(e) {
      $('.flexMe').matchHeight({
        byRow: false
      });
    });
  }

})(jQuery);
