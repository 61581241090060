/** ========================================================================
 * Magnific Popup Implemenation
 * These scripts are the standard uses for popups.
 * ======================================================================== */
(function ($) {
    function changeCta(datamodalcta){
        if($('.mfp-content').length) {
            // Element has this attribute
            $(".mfp-content .data-modalcta").detach();
            $(".mfp-container").removeClass("mfp-cta");
            if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                // this adds the new cta if one is associated right after the click function.
                $('.mfp-content').append($('#' + datamodalcta).clone());
                // this adds a class for style chaged based on cta pressence
                $(".mfp-container").addClass("mfp-cta");
            }
        } else {
            setTimeout(function(){
                changeCta(datamodalcta);
            }, 50);
        }
    }
    // Video Calls
    $('.popup-video, a[href*="player.vimeo"], a[href*="youtube.com/embed"]').magnificPopup({
        type: 'iframe',
        removalDelay: 500,
        mainClass: 'mfp-fade',
        closeOnContentClick: false,
        callbacks: {
            // markupParse is fired on every load/change
            markupParse: function (template, values, item) {
                // This sets up the title to pull from the currItem
                values.title = item.el.attr('title');
            },
            // open fires only on the initial load.
            open: function () {
                if (this + '[href*="player.vimeo"]') {
                    // For Vimeo Tracking
                    /* jshint ignore:start */
                    "undefined" != typeof bindVimeoGA && typeof bindVimeoGA == typeof Function && bindVimeoGA(this.currItem.el)
                    /* jshint ignore:end */
                }
              // sets up the current item data attribute variable
              var datamodalcta = this.currItem.el.attr('data-modalcta');
              // appendes the appropriate CTA if the video has one associated
              if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                $('.mfp-content').append($('#' + datamodalcta).clone());
                $(".mfp-container").addClass("mfp-cta");
              }
            }
        }
    });
    $('.iframePop').each(function() {
        $(this).magnificPopup({
            type: 'iframe',
            removalDelay: 500,
            mainClass: 'mfp-fade',
            closeOnContentClick: false,
            delegate: '.popup-gallery',
            callbacks: {
                // markupParse is fired on every load/change
                markupParse: function (template, values, item) {
                    // This sets up the title to pull from the currItem
                    values.title = item.el.attr('title');

                },
                // open fires only on the initial load.
                open: function () {
                    if (this + '[href*="player.vimeo"]') {
                        // For Vimeo Tracking
                        /* jshint ignore:start */
                        "undefined" != typeof bindVimeoGA && typeof bindVimeoGA == typeof Function && bindVimeoGA(this.currItem.el)
                        /* jshint ignore:end */
                    }
                    // sets up the current item data attribute variable
                    var datamodalcta = this.currItem.el.attr('data-modalcta');
                    // appendes the appropriate CTA if the video has one associated
                    if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                        $('.mfp-content').append($('#' + datamodalcta).clone());
                        $(".mfp-container").addClass("mfp-cta");
                    }
                },
                // change fires after the popup is loaded and the next/previous arrows are clicked
                change: function() {
                    // the change function is where most of this happens
                    // this sets up the current item data attribute as a variable
                    var datamodalcta = this.currItem.el.attr('data-modalcta');
                    changeCta(datamodalcta);
                },

            },
            gallery:{
                enabled:true,
                navigateByImgClick: false,
                arrowMarkup: '<button title="%title%" onclick="" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                tPrev: 'Previous (Left arrow key)',
                tNext: 'Next (Right arrow key)'
            },
        });
    });
    $('.imagePop').each(function() {
        $(this).magnificPopup({
            type: 'image',
            removalDelay: 500,
            mainClass: 'mfp-fade',
            closeOnContentClick: false,
            delegate: '.img-gallery',
            markup: '<div class="mfp-figure">'+
            '<div class="mfp-close"></div>'+
            '<div class="mfp-img"></div>', // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button

            callbacks: {
                // markupParse is fired on every load/change
                markupParse: function (template, values, item) {
                    // This sets up the title to pull from the currItem
                    //values.title = item.el.attr('title');

                },
                // open fires only on the initial load.
                open: function () {
                    if (this + '[href*="player.vimeo"]') {
                        // For Vimeo Tracking
                        /* jshint ignore:start */
                        "undefined" != typeof bindVimeoGA && typeof bindVimeoGA == typeof Function && bindVimeoGA(this.currItem.el)
                        /* jshint ignore:end */
                    }
                    // sets up the current item data attribute variable
                    var datamodalcta = this.currItem.el.attr('data-modalcta');
                    // appendes the appropriate CTA if the video has one associated
                    if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                        $('.mfp-content').append($('#' + datamodalcta).clone());
                        $(".mfp-container").addClass("mfp-cta");
                    }
                },
                // change fires after the popup is loaded and the next/previous arrows are clicked
                change: function() {
                    // the change function is where most of this happens
                    // this sets up the current item data attribute as a variable
                    var datamodalcta = this.currItem.el.attr('data-modalcta');
                    changeCta(datamodalcta);
                },

            },
            gallery:{
                enabled:true,
                navigateByImgClick: false,
                arrowMarkup: '<button title="%title%" onclick="" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
                tPrev: 'Previous (Left arrow key)',
                tNext: 'Next (Right arrow key)'
            },
        });
    });

    // Show gallery images in popup
    $('.img-pop').magnificPopup({
        type: 'image',
        removalDelay: 500,
        mainClass: 'mfp-fade',
        closeOnContentClick: false,
        callbacks: {
            // markupParse is fired on every load/change
            markupParse: function(template, values, item) {
                // This sets up the title to pull from the currItem
                //values.title = item.el.attr('title');
            },
            // open fires only on the initial load.
            open: function () {
                // sets up the current item data attribute variable
                var datamodalcta = this.currItem.el.attr('data-modalcta');
                // appendes the appropriate CTA if the video has one associated
                if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                    $('.mfp-content').append($('#' + datamodalcta).clone());
                    $(".mfp-container").addClass("mfp-cta");
                }
            },
// leaving this will reset the attached cta and only re-pop the cta if the specific item is clicked OR on any change in the gallery.
            change: function() {
                // the change function is where most of this happens
                // this sets up the current item data attribute as a variable
                var datamodalcta = this.currItem.el.attr('data-modalcta');
                changeCta(datamodalcta);
            },
        },
    });
    // Show pages in iframe
    $('.popup-page, a[href*="iframe=true"]').magnificPopup({
        type: 'iframe',
        removalDelay: 500,
        mainClass: 'mfp-fade',
        closeOnContentClick: false,
        closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close tabbed-close">× Close Window</button>',
        iframe: {
            markup: '<div class="mfp-iframe-scaler tab-frame">' +
            '<button title="Close (Esc)" type="button" class="mfp-close tabbed-close">× Close Window</button>' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            '</div>',
            srcAction: 'iframe_src',
        },
        callbacks: {
            open: function () {
                $('iframe').iFrameResize([{}]);
            }
        }
    });
    // Show gallery images in popup
    $('.gallery-row a').magnificPopup({
        type: 'image',
        removalDelay: 500,
        mainClass: 'mfp-fade',
        closeOnContentClick: false,
        gallery: {
            enabled: true,
            navigateByImgClick: false,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tPrev: 'Previous (Left arrow key)',
            tNext: 'Next (Right arrow key)'
        },
        callbacks: {
            // markupParse is fired on every load/change
            markupParse: function(template, values, item) {
                // This sets up the title to pull from the currItem
                values.title = item.el.attr('title');
            },
            // open fires only on the initial load.
            open: function () {
              // sets up the current item data attribute variable
              var datamodalcta = this.currItem.el.attr('data-modalcta');
              // appendes the appropriate CTA if the video has one associated
              if (typeof datamodalcta !== typeof undefined && datamodalcta !== false) {
                $('.mfp-content').append($('#' + datamodalcta).clone());
                $(".mfp-container").addClass("mfp-cta");
              }
            },
// leaving this will reset the attached cta and only re-pop the cta if the specific item is clicked OR on any change in the gallery.
            change: function() {
                // the change function is where most of this happens
                // this sets up the current item data attribute as a variable
                var datamodalcta = this.currItem.el.attr('data-modalcta');
                changeCta(datamodalcta);
            },
        },
    });
})(jQuery);
