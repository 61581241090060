/** ========================================================================
 * Hide Astrisk
 * This will add a class to hide the astrisk on forms for required fields if no label is added.
 * ======================================================================== */
(function ($) {
    $(document).on('gform_post_render ready', function () {
        $('.gfield_label').each(function (e) {
            if ($(this).text() === '*') {
                $(this).parent().addClass('hideLabel');
            }
        });
        $('.gfield_error').each(function () {
            $(this).focusout(function () {
                $(this).find('.validation_message').css('opacity', '0');
            });
        });
    });
})(jQuery);