/** ========================================================================
 * Gravity Forms Clientside Validation
 * This will give validation while users fill-out the form.
 * ======================================================================== */
(function ($) {

  // Define basic input and textarea to check
  var basic_elements = 'form.clientsideValidate div.ginput_container > input:not(.gform_hidden), form.clientsideValidate div.ginput_container > textarea';
  var formValid = true;


  // Validation Functions
  function validateEmail(sEmail) { // Validate Email
    var filter = /^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,4}$/;
    if (filter.test(sEmail)) {
      return true;
    } else {
      return false;
    }
  }

  function validatePhone(sPhone) { // Validate Phone
    var filter = /[0-9 -()+]+$/;
    if (filter.test(sPhone)) {
      return true;
    } else {
      return false;
    }
  }

  // Returns
  function inputSuccess(e) {

    // Add Valid Class
    $(e).parent().parent().removeClass('gfield_error').removeClass('valueFail').addClass('valueValid');

    // Add form control if doens't exist
    $(e).addClass('form-control');

    // Remove other result classes and add success
    $(e).parent().removeClass('has-error').removeClass('has-warning').addClass('has-success');

    // Remove other mark
    $(e).next().remove();
    // Add Check Mark
    $(e).after('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>');

    // Remove error message
    $(e).parent().next().remove();
  }

  function inputWarning(e) {

    // Remove Valid Class
    $(e).parent().parent().removeClass('valueValid').addClass('valueFail');

    // Add form control if doens't exist
    $(e).addClass('form-control');

    // Remove other result classes and add success
    $(e).parent().removeClass('has-error').removeClass('has-success').addClass('has-warning');

    // Remove other mark
    $(e).next().remove();
    // Add Check Mark
    $(e).after('<span class="glyphicon glyphicon-warning-sign form-control-feedback" aria-hidden="true"></span>');
  }

  function inputError(e) {

    // Remove Valid Class
    $(e).parent().parent().addClass('valueFail').removeClass('valueValid');

    // Add form control if doens't exist
    $(e).addClass('form-control');

    // Remove other result classes and add success
    $(e).parent().removeClass('has-success').removeClass('has-warning').addClass('has-error');

    // Remove other mark
    $(e).next().remove();
    // Add Check Mark
    $(e).after('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>');
  }

  function inputDefault(e) {

    // Remove Valid Classes
    $(e).parent().parent().removeClass('valueValid').removeClass('valueFail');

    // Add form control if doens't exist
    $(e).removeClass('form-control');

    // Remove other result classes and add success
    $(e).parent().removeClass('has-success').removeClass('has-warning').removeClass('has-error');

    // Remove other mark
    $(e).next().remove();
  }

  // Check input and textarea
  function fieldCheck(field) {

    if (!$(field).val()) { // Field doesn't have value
      if ($(field).parent().parent().hasClass('gfield_contains_required')) { // Required Field
        $(field).parent().parent().addClass('valueFail').removeClass('valueValid');
        inputError(field);
      } else { // Not required field
        $(field).parent().parent().removeClass('hasValue').removeClass('valueValid').removeClass('valueFail');
        inputDefault(field);
      }

    } else { // Field has value
      if ($(field).is('[type|=email]')) { // Is email
        var email = $(field).val();
        if (validateEmail(email) /*&& !$(this).next().hasClass('glyphicon-ok')*/) {  // if return good email
          inputSuccess(field);

        } else {  // if return bad email
          formValid = false;
          inputWarning(field);
        }
      } else if ($(field).is('[type|=tel]')) { // Is Telephone
        var phone = $(field).val();
        if (validatePhone(phone)) { // Is good
          inputSuccess(field);
        } else if ($(field).parent().parent().hasClass('gfield_contains_required')) { // Is bad and required
          formValid = false;
          inputError(field);
        } else { // Is bad but isn't required
          formValid = false;
          inputDefault(field);
        }
      } else { // Default has value
        inputSuccess(field);
      }
    }
  }

  // Check select
  function selectCheck(field) {
    if ($(field).val() === "") { // Field doesn't have value
      formValid = false;
      if ($(field).parent().parent().hasClass('gfield_contains_required')) { // Required Field
        $(field).parent().parent().addClass('valueFail').removeClass('valueValid');
        inputError(field);
      } else { // Not required field
        $(field).parent().parent().removeClass('hasValue').removeClass('valueValid').removeClass('valueFail');
        inputDefault(field);
      }

    } else { // Field has value
      inputSuccess(field);
    }
  }

  $(document).bind('gform_post_render', function () { // on page load and after form submit
    var con_fields = false;
    $(basic_elements).focusin(function () { // Field is selected
      $(this).parent().parent().addClass('hasValue');
    });
    $(basic_elements + ', .gfield_select').focusout(function () { // Field is unselected
      if ($(this).is('select')) {
        selectCheck(this);
      } else {
        fieldCheck(this);
      }
    });

    /* Button Effects - Not Done
     $('.gform_button').on( "mouseenter", function() {
     formValid = true;
     $(basic_elements + ', .gfield_select').each(function() {
     if ( $(this).is('select') ) {
     selectCheck(this);
     } else {
     fieldCheck(this);
     }
     });
     if ( formValid === false ) {
     $(basic_elements + ', .gfield_select').each(function() {
     if ( !$(this).parent().parent().hasClass('gfield_contains_required') || $(this).parent().parent().hasClass('gfield_contains_required') && $(this).parent().parent().hasClass('valueValid') ) {
     $(this).parent().parent().css('opacity', '0.5');
     }
     });
     $(this).tooltip({
     placement : 'right',
     title : 'Fail'
     }).tooltip('show');
     }
     });
     $('.gform_button').on( "mouseleave", function() {
     $(this).tooltip('destroy');
     });*/
  });

})(jQuery);
