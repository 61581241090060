// a place for scripts without a home
(function( $ ) {

  $(document).ready(function() {

    //reset urls for virtual rabbit on no-webgl to use the flash version.
    if ($('html').hasClass("no-webgl")) {
      $('a[href*="rabbit"]').each(function () {
        this.href = this.href.replace('embed\/', 'viewer\/ext12.swf');
      });
    }

    //Home Full Image Module Overflow
    function mFullHeight() {
      $eHeight = $('.mFullImgContent .content').outerHeight();
      $('.mFullImgContent').css('min-height', $eHeight);
    }
    if ( $('.mFullImgContent').length ) {
      mFullHeight();
      $(window).resize(function () {
        mFullHeight();
      });
      mFullHeight();
    }

    // IE Edge fix for font resizer
    var ua = window.navigator.userAgent;
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      var sage_css = $('link[id$=css-css]');
      $(sage_css).clone().insertAfter(sage_css);
      var sage_href = sage_css.attr("href");

      $('#resize a').click(function(e) {
        sage_css.attr('href', sage_href);
      });
    }
  });

  // For Backstop.js
  window.onload = function() {
    console.log("backstopjs_ready");
  };

})( jQuery );
