/** ========================================================================
 * Flash Test
 * This will ifloor in the url and rewrite the url if flash isn't available.
 * ======================================================================== */
(function ($) {
    if ($("a[href*='ifloor']")) {
        var yoFlash = (function (a, b) {
            try {
                a = new ActiveXObject(a + b + '.' + a + b);
            } catch (e) {
                a = navigator.plugins[a + ' ' + b];
            }
            return !!a;
        }('Shockwave', 'Flash'));
        if (yoFlash) {
            // Flash
        } else {
            // No Flash
            $("a[href*='ifloor']").each(function () {
                var lnk = this.href;

                // URL rewrite
                this.href = lnk.replace("ifloor.html?plan=", "ipad/index.html#");
            });
        }
    }
})(jQuery);

