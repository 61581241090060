/** ========================================================================
 * Anchor Link Smooth Scrolling
 * Make the scrolling to an anchor link scroll smoothly.
 * ======================================================================== */
(function ($) {
    $('a[href*="#"]:not([href="#"]):not([data-toggle="collapse"])').click(function() {
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 1500);
                return false;
            }
        }
    });
})(jQuery);
